import { useCallback, memo } from 'react'
import { observer } from 'mobx-react'

import {
  ApiEnvironment,
  ErrorCode,
  Fintec as Dac,
  FintecState,
  TenantUuid,
  PrefillTypes
} from '@finanzcheck/ti-fts-widget'

import { trackInAmplitude } from 'utils/tracking/amplitude'
import { Card } from '@mui/material'
import { useStores } from 'stores/utils/hooks/useStores'
import { DacWidgetTabEnum } from 'stores/TraversalStore'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { useData } from 'stores/utils/hooks/useData'

import DacWidgetSmava from 'components/Smava/Dac/DacWidget'

import { getEnvMulti } from '../../utils/env'
import { AmplitudeEvent } from '../../utils/tracking/amplitude/amplitude.interface'

import DacWidget from './DacWidget'
import IntroText from './IntroText'
import DacChoice from './DacChoice'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import DACInitializedError from 'error/dacInitializedError'
import { SuccessfulDacLog } from 'error/successfulDacLog'
import getExperimentVariation from 'utils/tracking/optimizely/getExperimentVariation'
import { PossibleVariants } from 'utils/tracking/optimizely/types'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { Experiments } from 'CustomOptimizelyProvider'
import { useUrlParams } from 'stores/utils/hooks/useUrlParams'
import { getAge } from 'utils/date'
import { CONFIG_DDF_SEPARATED_DAC } from 'config/utils/config'

type DigitalAccountCheckProps = {
  personId: string
  traversalId: string
  accountValue: string
  origin?: 'taurine'
  isBannerIconOutline?: boolean
  dacSmavaVariant?: boolean // to be refined, get rid of `Smava`
  skipIbanGate?: boolean
  accountType: PrefillTypes
  submitButtonStep1Label?: string
  submitButtonStep2Label?: string
  insertDACHandIcon?: boolean
  dacSmavaHandIcon?: boolean // to be refined, get rid of `Smava`
  isSuccessMessageHidden?: boolean
  bankCodeLabel?: string
  bankCodePlaceholder?: string
}

export enum DACEvent {
  STATE_CHANGE_AGREED = 'dac_onStateChange_yes',
  STATE_CHANGE_DISAGREED = 'dac_onStateChange_no',
  ON_SUCCESS = 'dac_onSuccess'
}

const DigitalAccountCheck = observer(
  ({
    personId,
    traversalId,
    accountValue,
    origin = 'taurine',
    isBannerIconOutline = false,
    dacSmavaVariant,
    skipIbanGate,
    accountType,
    submitButtonStep1Label,
    submitButtonStep2Label,
    insertDACHandIcon,
    dacSmavaHandIcon,
    isSuccessMessageHidden,
    bankCodeLabel,
    bankCodePlaceholder
  }: DigitalAccountCheckProps) => {
    const {
      traversal: {
        setDacWidgetState,
        dacWidgetTab,
        setDacWidgetStep,
        setIsDacSkipped,
        setDacTransactionDetails,
        setIsDacInitializedFlag
      }
    } = useStores()
    const { rootStore } = useRootStore()
    const { data } = useData()
    const urlParams = useUrlParams()

    const trackWithOrigin = useCallback(
      (event: AmplitudeEvent, payload = {}) => {
        trackInAmplitude(event, { origin, ...payload })
      },
      [origin]
    )

    const {
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { firstname, lastname, birthday }
            }
          }
        }
      },
      page: {
        config: { name }
      }
    } = useStores()

    const handleSuccess = useCallback(
      (transactionId: string) => {
        setDacTransactionDetails(transactionId)
        logAndTrackError(
          new SuccessfulDacLog(traversalId, personId, transactionId)
        )
        broadcastToParent({
          eventName: RegisteredEventName.tracking,
          data: {
            event: DACEvent.ON_SUCCESS,
            context: {
              Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
              ...getOptimizelyTrackingAttribute(data)
            }
          }
        })
        trackWithOrigin(AmplitudeEvent.OnDacSuccess)
        setIsDacSkipped(false)
        setDacWidgetState('success')
      },
      [
        setDacTransactionDetails,
        traversalId,
        personId,
        data,
        trackWithOrigin,
        setIsDacSkipped,
        setDacWidgetState
      ]
    )

    const handleError = useCallback(
      (code: ErrorCode, messages: string[], isRecoverable: boolean) => {
        trackWithOrigin(AmplitudeEvent.OnDacError, {
          code,
          messages,
          isRecoverable
        })
      },
      [trackWithOrigin]
    )

    const handleAbort = useCallback(() => {
      trackWithOrigin(AmplitudeEvent.OnDacAbort)
    }, [trackWithOrigin])

    const handleStateChange = useCallback(
      (state: FintecState) => {
        trackWithOrigin(AmplitudeEvent.OnDacStateChange, { state })

        // widget state is being set to 'success' in handleSuccess as adviced by Players
        if (state !== 'success') {
          setDacWidgetState(state)
        }
      },
      [trackWithOrigin, setDacWidgetState]
    )

    const handleTrack = (
      event: string,
      trackingData?: Record<string, unknown> | undefined
    ) => {
      if (Object.values(AmplitudeEvent).includes(event as AmplitudeEvent)) {
        trackWithOrigin(event as AmplitudeEvent)
      }

      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            userAttributes: {
              ...trackingData
            },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })
    }

    const handleStepChange = useCallback(
      (step: string) => {
        if (
          (step === 'BANK_SELECTION_STEP' ||
            step === 'BANK_LOGIN_STEP' ||
            step === 'IBAN_GATE_STEP') &&
          !rootStore.experimentData[Experiments.CAT_2739_DAC_6_ABORTED_LEADS] &&
          !rootStore.experimentData[
            Experiments.CAT_2781_DAC_6_ABORTED_LEADS_FFG
          ]
        ) {
          const overrideAttributes = {
            utm_source: urlParams.utm_source || ''
          }

          const cat2739Variation = getExperimentVariation(
            Experiments.CAT_2739_DAC_6_ABORTED_LEADS,
            rootStore,
            { ...overrideAttributes }
          )

          const cat2781Variation = getExperimentVariation(
            Experiments.CAT_2781_DAC_6_ABORTED_LEADS_FFG,
            rootStore,
            { ...overrideAttributes }
          )

          setIsDacInitializedFlag(
            cat2739Variation?.variation === PossibleVariants.VARIATION_B ||
              cat2781Variation?.variation === PossibleVariants.VARIATION_B
          )
        }
        setDacWidgetStep(step)
      },
      [setDacWidgetStep, rootStore, setIsDacInitializedFlag, urlParams]
    )

    const [apiEnvironment, tenantUuid] = getEnvMulti([
      'REACT_APP_FINTEC_ENV',
      'REACT_APP_TENANT_UUID'
    ])

    const debtorsAge = getAge(birthday)

    const variationDac7 =
      rootStore.experimentData[Experiments.CAT_2943_DAC_7_AGE_GROUP]

    const isInCAT2943YoungAge =
      (variationDac7 === PossibleVariants.VARIATION_B && debtorsAge < 55) ||
      variationDac7 === PossibleVariants.CONTROL ||
      !variationDac7

    if (origin && tenantUuid) {
      return (
        <Dac
          account={{
            iban: accountValue,
            type: accountType,
            bankCode: accountValue
          }}
          system={{
            origin,
            personId,
            environment: apiEnvironment as ApiEnvironment,
            clientUuid: tenantUuid as TenantUuid,
            traversalUuid: traversalId,
            checkPeriodDays: 'long_term',
            firstName: firstname,
            lastName: lastname,
            ...(submitButtonStep1Label && { submitButtonStep1Label }),
            ...(submitButtonStep2Label && { submitButtonStep2Label })
          }}
          onTrack={handleTrack}
          onSuccess={handleSuccess}
          onError={handleError}
          onAbort={handleAbort}
          onStateChange={handleStateChange}
          onStepChange={handleStepChange}
          skipIbanGate={skipIbanGate}
        >
          {({ state, setState, widget }) => {
            if (
              (dacSmavaVariant && isInCAT2943YoungAge) ||
              (dacSmavaVariant && name === CONFIG_DDF_SEPARATED_DAC)
            ) {
              if (dacWidgetTab === DacWidgetTabEnum.SLOW) {
                return null
              }

              return (
                <>
                  <DacWidgetSmava
                    state={state}
                    setState={setState}
                    widget={widget}
                    insertDACHandIcon={insertDACHandIcon}
                    dacSmavaHandIcon={dacSmavaHandIcon}
                    isSuccessMessageHidden={isSuccessMessageHidden}
                    bankCodeLabel={bankCodeLabel}
                    bankCodePlaceholder={bankCodePlaceholder}
                  />
                </>
              )
            }

            return (
              <Card variant="outlined">
                <IntroText />
                <DacChoice
                  state={state}
                  setState={setState}
                  isBannerIconOutline={isBannerIconOutline || false}
                />
                <DacWidget state={state} widget={widget} />
              </Card>
            )
          }}
        </Dac>
      )
    }
    logAndTrackError(new DACInitializedError({ origin, tenantUuid }))
    return null
  }
)

export default memo(DigitalAccountCheck)
