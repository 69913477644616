import { lazy, useCallback, useEffect, memo, Suspense } from 'react'
import { useNavigate } from 'react-router-dom'

import Bugsnag from '@bugsnag/js'

import { observer } from 'mobx-react'

import AbortDetector from 'AbortDetector'
import Heartbeat from 'Heartbeat'
import IdleDetector from 'containers/IdleDetector'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { startApp } from 'startup'
import { useUrlParams } from 'stores/utils/hooks/useUrlParams'
import { useStores } from 'stores/utils/hooks/useStores'
import {
  setGlobalEventProperty,
  trackInAmplitude
} from 'utils/tracking/amplitude'
import {
  AmplitudeEvent,
  EventProperty
} from 'utils/tracking/amplitude/amplitude.interface'
import getExperimentVariation from 'utils/tracking/optimizely/getExperimentVariation'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import { ErrorProvider } from 'context/Error'
import { UserProvider } from 'context/User'
import { StylesProvider } from '@mui/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import { generateClassName } from 'theme/material'
import { Experiments } from 'CustomOptimizelyProvider'

import Routes from './Routes'
import LoadingProvider, { LoadingMode } from './components/LoadingProvider'
import { isDevMenuEnabled } from './utils/env'
import ProgressProvider from './ProgressContext'
import ThemeProvider from './ThemeProvider'

/**
 * We load the container lazily here
 * because we don't want it to be loaded in the production bundle
 */
const DevContainer = isDevMenuEnabled()
  ? lazy(() => import('./devTools/DevContainer'))
  : () => null

const Metadata = observer(() => {
  const { traversal } = useStores()
  const {
    traversalId,
    data: {
      system: { advertisementId }
    }
  } = traversal

  useEffect(() => {
    setGlobalEventProperty(EventProperty.TraversalId, traversalId)

    Bugsnag.addMetadata('traversal', {
      id: traversalId,
      advertisementId
    })
  }, [traversalId, advertisementId])

  return null
})

const App = () => {
  const {
    rootStore: { resumeDetailsFetched },
    rootStore
  } = useRootStore()

  const urlParams = useUrlParams()
  const navigate = useNavigate()

  const boot = useCallback(() => {
    rootStore.setLoadingMode(LoadingMode.Initial)

    try {
      trackInAmplitude(AmplitudeEvent.OnTaurineStartsLoading, {
        timestamp: Number(new Date())
      })
      startApp(rootStore, navigate, urlParams)
    } catch (error) {
      if (error instanceof Error) {
        logAndTrackError(error)
      }
    } finally {
      rootStore.setLoadingMode(LoadingMode.None)
    }
  }, [rootStore, urlParams, navigate])

  useEffect(() => {
    boot()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // eslint-disable react-hooks/exhaustive-deps
    getExperimentVariation(Experiments.CAT_2943_DAC_7_AGE_GROUP, rootStore, {
      utm_source: urlParams.utm_source || ''
    })
    getExperimentVariation(Experiments.CAT_3012_NEWSLETTER, rootStore, {
      utm_source: urlParams.utm_source || ''
    })
  }, [resumeDetailsFetched])

  /*
    This is useEffect for calling an Amplitude event: OnTaurineBecomesUsable.
    If `.MuiSkeleton-pulse` exists in DOM/body that means inputs are still loading.
    When they are all gone, it means Taurine if fully interactive.
   */
  useEffect(() => {
    const callback = (
      mutationsList: MutationRecord[],
      bodyObserver: MutationObserver
    ) => {
      for (const mutationList of mutationsList) {
        if (mutationList.removedNodes.length > 0) {
          const skeletonPulse = document.querySelector('.MuiSkeleton-pulse')

          if (!skeletonPulse) {
            trackInAmplitude(AmplitudeEvent.OnTaurineBecomesUsable, {
              timestamp: Number(new Date())
            })
            bodyObserver.disconnect()
            break
          }
        }
      }
    }

    const bodyObserver = new MutationObserver(callback)
    const body = document.querySelector('body')
    const config = { childList: true, subtree: true }

    if (body) {
      bodyObserver.observe(body, config)
    }

    return () => {
      bodyObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <StylesProvider generateClassName={generateClassName}>
          <ProgressProvider>
            <UserProvider>
              <ErrorProvider>
                <LoadingProvider>
                  <Routes navigate={navigate} />
                </LoadingProvider>
              </ErrorProvider>
              <IdleDetector />
            </UserProvider>
            <Metadata />
            <Heartbeat />
            <AbortDetector />
            <Suspense fallback={null}>
              <DevContainer />
            </Suspense>
          </ProgressProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default memo(App)
